// 根据用户手机号获取人员走访信息
const getVisPerInfoByMobileUrl = `/gateway/hc-arch/visiting/getVisPerInfoByMobile`;
// 删除走访记录
const deleteRecInfoUrl = `/gateway/hc-arch/visiting/deleteRecInfo`;
// 根据id获取走访详情
const getRecDetailsUrl = `/gateway/hc-arch/visiting/getRecDetails`;
// 获取走访记录列表
const getRecInfoListUrl = `/gateway/hc-arch/visiting/getRecInfoList`;
// 获取走访人负责的公司信息
const getCompanyListUrl = `/gateway/hc-arch/visiting/getCompanyList`;
// 新增走访记录;
const addVisRecInfoUrl = `/gateway/hc-arch/visiting/addVisRecInfo`;
// 获取用户信息接口
const userInfoUrl = `/gateway/hc-mini/user/mini/user-info`;
export {
  getVisPerInfoByMobileUrl,
  deleteRecInfoUrl,
  getRecDetailsUrl,
  getRecInfoListUrl,
  getCompanyListUrl,
  addVisRecInfoUrl,
  userInfoUrl,
};
