<template>
  <div class="page">
    <div class="list">
      <van-swipe-cell v-for="(item, index) in list" :key="index">
        <div class="item" @click="toDetail(item.id)">
          <div class="topBox">
            <div class="name">{{ item.companyName }}</div>
            <div class="time">{{ item.createTime }}</div>
          </div>
          <div class="picList">
            <img
              v-for="(oitem, oindex) in item.imageUrl.split(',')"
              :key="oindex"
              :src="oitem"
            />
          </div>
          <div class="place">地点：{{ item.visitingAddress }}</div>
        </div>
        <template #right>
          <div class="right_remove">
            <img @click="remove(item)" src="./img/delete.png" />
          </div>
        </template>
      </van-swipe-cell>
    </div>
    <div class="btn" @click="toAdd()">添加走访</div>
    <v-dialog
      confirmButtonColor="#7571f8"
      v-model="isDialog"
      title="提示"
      @confirm="removeSubmit"
    >
      <p class="dialogmessage">是否确认删除该条走访记录？</p>
    </v-dialog>
  </div>
</template>

<script>
import {
  getVisPerInfoByMobileUrl,
  deleteRecInfoUrl,
  getRecDetailsUrl,
  getRecInfoListUrl,
  getCompanyListUrl,
  addVisRecInfoUrl,
  userInfoUrl,
} from "./api.js";
import { mapState } from "vuex";
export default {
  name: "myVisit",
  data() {
    return {
      isDialog: false,
      visitingId: null,
      list: [],
      id: null,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  async created() {
    await this.getVisitingId();
  },
  mounted() {
    this.getList();
  },
  methods: {
    removeSubmit() {
      let params = {
        id: this.id,
      };
      this.$axios
        .post(`${deleteRecInfoUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code == 200) {
            this.isDialog = false;
            this.getList();
            this.$toast("操作成功！");
          }
        });
    },
    remove(item) {
      this.id = item.id;
      this.isDialog = true;
    },
    async getList() {
      let params = {
        visitingId: sessionStorage.getItem("visitingId"),
      };
      let res = await this.$axios.post(`${getRecInfoListUrl}`, params, {
        headers: { "Content-Type": "application/json" },
      });
      if (res.code == 200) {
        this.list = res.data;
      }
    },
    async getVisitingId() {
      let params = {
        userId: this.userId,
      };
      let res = await this.$axios.get(`${userInfoUrl}`, {
        params,
      });
      if (res.code == 200) {
        this.getVisPerInfoByMobile(res.data.mobile);
      }
    },
    async getVisPerInfoByMobile(mobile) {
      let params = {
        mobile: mobile,
      };
      let res = await this.$axios.get(`${getVisPerInfoByMobileUrl}`, {
        params,
      });
      if (res.code == 200) {
        sessionStorage.setItem("mobile", mobile);
        sessionStorage.setItem("visitingId", res.data.id);
      }
    },
    toAdd() {
      this.$router.push({
        name: "addMyVisit",
      });
    },
    toDetail(id) {
      this.$router.push({
        name: "myVisitDetail",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  padding: 30px;
  background: #fafafa;
  box-sizing: border-box;
  .dialogmessage {
    text-align: center;
    font-size: 30px;
    color: rgba(0, 0, 0, 0.9);
    padding: 20px 0;
  }
  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #9490ff 0%, #5f5bff 100%);
    border-radius: 10px;
    text-align: center;
    line-height: 66px;
    font-size: 30px;
    font-weight: 600;
    color: #ffffff;
    position: fixed;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
  }
  .list {
    box-sizing: border-box;
    padding-bottom: 150px;
    .item {
      width: 100%;
      height: 380px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 16px;
      padding: 32px 36px 0 44px;
      margin-bottom: 22px;
      box-sizing: border-box;
      .place {
        font-size: 24px;
        color: #333333;
      }
      .picList {
        width: 100%;
        height: 186px;
        display: flex;
        margin: 24px 0;
        img {
          display: flex;
          width: 186px;
          height: 100%;
          margin-right: 20px;
        }
        img:nth-last-child(1) {
          margin-right: 0;
        }
      }
      .topBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .name {
          font-size: 28px;
          color: #333333;
        }
        .time {
          font-size: 24px;
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
    .right_remove {
      width: 100%;
      height: 100%;
      padding-left: 20px;
      display: flex;
      align-items: center;
      img {
        width: 74px;
        height: 74px;
        display: block;
      }
    }
  }
}
</style>
